@import "~antd/dist/antd.css";

body {
  height: 100vh;
  font-family: "Asap", sans-serif;
}

.app {
  height: 100%;
}

.app-init-state {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

h2.ant-typography {
  font-weight: 400;
}
