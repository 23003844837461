.login-screen {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .login-form {
    margin: 0 auto;
    width: 400px;

    .login-form__icon {
      font-size: 92px;
    }
    .login-form__description {
      font-size: 18px;
    }
  }
}
