.search-view {
  width: 100%;
  margin-left: -20px;

  .search-bar {
    width: 100%;
    margin: 20px auto;
  }

  .search-results {
    width: 100%;
    margin: 10px auto;

    .search-results__item {
      font-size: 16px;
      padding-top: 5px;
    }
  }

  .search-no-results {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
