.focus-card {
  min-width: 300px;
  max-width: 320px;
  margin: 10px;

  .ant-card-body {
    padding: 10px 24px;
  }

  .ant-list-items {
    .ant-list-item {
      padding: 10px 0 0 0;
    }
  }
}
