.hamburger-icon {
  cursor: pointer;
  margin: 25px 15px;

  & > div {
    width: 40px;
    height: 5px;
    background-color: black;
    margin: 6px 0;
  }
}
