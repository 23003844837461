.horisontal-navigation {
  font-size: 14px;
  background: #fff;

  h1 {
    padding-top: 15px;

    .logo {
      height: 57px;
      padding-left: 40px;
      overflow: hidden;
      color: rgba(0, 0, 0, 0.85);
      font-size: 18px;
      font-family: Avenir, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
        Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
        Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
      line-height: 57px;
      white-space: nowrap;
      text-decoration: none;

      img {
        position: relative;
        top: -1.5px;
        height: 32px;
        margin-right: 16px;
      }
    }
  }
}
