.library-onboarding-view {
  width: 90%;
  margin: 0 auto;

  .library-sample-collection {
    margin-right: 10px;
  }

  .library-description {
    margin: 10px;
  }
}
