.placeholder-focus-card {
  position: relative;

  width: 300px;
  height: 180px;
  margin: 10px;

  cursor: pointer;

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    text-align: center;

    &__icon {
      font-size: 48px;
    }

    &__label {
      display: block;
      font-size: 28px;
    }
  }

  .ant-card-body {
    padding: 10px 24px;
    height: 100%;
  }

  .ant-list-items {
    .ant-list-item {
      padding: 10px 0 0 0;
    }
  }
}
