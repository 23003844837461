.collection-details {
  max-width: 1200px;
  height: 100%;

  margin: 0 auto;

  background: #fff;

  border-left: 1px #7c7c7c;
  border-right: 1px #7c7c7c;

  .page-component {
    padding: 0 30px;

    @media (max-width: 800px) {
      padding: 0 20px;
    }

    @media (max-width: 350px) {
      padding: 0 5px;
    }

    .collection-description {
      .collection-description__image {
        max-height: 150px;
      }

      .collection-description__text {
        padding-left: 10px;
      }
    }
  }
}
