.status-button {
  margin: 0 5px;
  font-weight: bold;

  &.commit {
    background-color: #fadb14;
    border: 1px solid #fadb14;
    color: #000;
  }

  &.finish {
    background-color: #52c41a;
    border: 1px solid #52c41a;
  }

  &.reset {
    background-color: #cf1322;
    border: 1px solid #cf1322;
  }
}
