.collection-card {
  width: 300px;
  margin: 10px;

  .ant-card-head {
    .ant-card-head-title > a {
      color: #1a6ce6;
      font-weight: 300;
    }
  }

  .ant-card-body {
    padding: 10px 24px;
  }

  .ant-list-items {
    .ant-list-item {
      padding: 10px 0 0 0;
    }
  }
}
