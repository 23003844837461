.tools-screen {
  padding-top: 10px;

  .tools-screen-links {
    width: 90%;
    margin: 0 auto;

    ul > li {
      margin-top: 8px;
      & > a {
        font-size: 16px;
      }
    }
  }
}
