.register-screen {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .register-form {
    margin: 0 auto;
    width: 400px;

    &__icon {
      font-size: 92px;
    }

    &__description {
      font-size: 18px;
    }
  }
}
