.mtdv-nav-menu {
  &.ant-menu-horizontal {
    margin: 10px 0;
    padding-left: 10px;
    border-bottom: none;

    & > .ant-menu-item,
    & > .ant-menu-submenu {
      min-width: 40px;
      height: 64px;
      margin-right: 12px;
      margin-left: 12px;
      line-height: 60px;
      border-top: 2px solid transparent;

      &:hover {
        border-top: 2px solid #1890ff;
        border-bottom: 2px solid transparent;
      }
    }

    & .ant-menu-submenu-title .anticon {
      margin: 0;
    }

    & > .ant-menu-item-selected {
      border-top: 2px solid #1890ff;
      border-bottom: 2px solid transparent;
      a {
        color: #1890ff;
      }
    }

    & > .ant-menu-item,
    & > .ant-menu-submenu {
      text-align: center;
    }
  }

  &.ant-menu-vertical {
    margin: -24px;
    border: none;
  }
}
